<template>
     <div class="w3-theme-l1" >
        <div class="w3-container w3-margin-top w3-margin-bottom">
            <Toolbar 
                :statusAdd="recAkses.add=='T'?true : false"
                :statusPrint="recAkses.print=='T'?true : false"
                :statusExport="recAkses.print=='T'?true : false"
                :click-add="showAdd"
                :click-refresh="loadData"
                :click-print="showPrint"
                :set-filter="setFilter"
                :field-find="filters.fields"
                help="Pm9euRNtPKw"
                :data   = "getRecs"
                :fields = "json_fields"
                type    = "xls"
                name    = "siswa.xls"
            >
                <div slot="button">
                    <a title="Add" class="w3-bar-item w3-btn w3-border-right w3-red" @click="showAdd()"><i class="fa fa-download"></i><span class="w3-hide-small"> Template</span></a>
                    <a title="Add" class="w3-bar-item w3-btn w3-border-right w3-yellow" @click="showAdd()"><i class="fa fa-upload"></i><span class="w3-hide-small"> Uplaod</span></a>
                </div>
            </Toolbar>
			<div class="w3-row">
				<div @mouseover="grid = true" @mouseleave="grid = false" >
                    <vue-scrolling-table v-bind:style="'height:'+  (screenHeight+40) +'px;'" :class="'w3-table w3-bordered w3-striped w3-white w3-small freezeFirstColumn freezeLastColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="true" id="grid">
                        <template slot="thead">
                            <tr class="w3-border-0 w3-border-right w3-border-bottom">
                                <th style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                    No
                                </th>
                                <th v-for="fl in $store.getters['modSiswa/filterByShow']" :key="fl.key" :style="'min-width:' + fl.width+
                                (fl['sort']['status']?';cursor:pointer':'')"
                                    class="w3-light-grey w3-border-0" >
                                    <div v-on:click="setFilterTable(fl)">{{fl.name}}
                                        <span v-if="fl['sort']['status']">
                                            <i class="fa"
                                                :class="fl['sort']['asc']=='asc'?'fa-sort-asc':(fl['sort']['asc']=='desc'?'fa-sort-desc':'fa-arrows-v')"></i>
                                        </span>
                                    </div>
                                </th>
                            </tr>
                        </template>
                        <template slot="tbody">
                            <tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in getRecs" style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == $store.getters['modSiswa/selectedRow'] ?'bayangan w3-theme-l4':'w3-opacity-min'))" :key="dtl.id" v-on:dblclick="showEdit(dtl, index);" class=" w3-hover-border-grey">
                                <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                    <i :class="index == $store.getters['modSiswa/selectedRow'] ?'fa fa-caret-right':''"></i>{{index+((pages.halaman-1)*pages.batas)+1}}
                                </td>
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','siswa_nis')"  class="w3-border-0 w3-border-left">
                                    <span v-html="setTextFind(dtl.siswa_nis,'siswa_nis')"></span>
                                </td>
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','siswa_email')"  class="w3-border-0 w3-border-left">
                                    <span v-html="setTextFind(dtl.siswa_email,'siswa_email')"></span>
                                </td>
                                
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','siswa_name')"  class="w3-border-0 w3-border-left">
                                    <span v-html="setTextFind(dtl.siswa_name,'siswa_name')"></span>
                                </td>
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','siswa_alamat')"  class="w3-border-0 w3-border-left">
                                    <span v-html="setTextFind(dtl.siswa_alamat,'siswa_alamat')"></span>
                                </td>
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','sekolah_kelas_code')"  class="w3-border-0 w3-border-left">
                                    <span v-html="setTextFind(dtl.sekolah_kelas_name,'sekolah_kelas_name')"></span>
                                </td>
                                
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','user_entry')"  class="w3-border-0 w3-border-left">
                                    <span v-html="setTextFind(dtl.user_entry,'user_entry')"></span>
                                </td>
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','date_entry')"  class="w3-border-0 w3-border-left">
                                    {{dtl.date_entry | moment("DD-MM-yyyy HH:mm:ss")}}
                                </td>
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','user_edit')"  class="w3-border-0 w3-border-left">
                                    <span v-html="setTextFind(dtl.user_edit,'user_edit')"></span>
                                </td>
                                <td v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','date_edit')"  class="w3-border-0 w3-border-left">
                                    {{dtl.date_edit | moment("DD-MM-yyyy HH:mm:ss")}}
                                </td>

                                <td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObjFind('width','siswa_status')" class="w3-border-0 w3-border-left w3-border-right w3-center">
                                    <div v-on:click="$store.dispatch('modSiswa/setSelectedRow',index)" style="position: relative;">
                                        <span v-html="setStatus(dtl.siswa_status)"></span>
                                        <div v-show="dtl['pilih']==true" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
                                            <button v-if="recAkses.edit=='T'" style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-pencil w3-large"></i></button>
                                            <button v-if="recAkses.delete=='T'" style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template slot="tfoot">
                            <Paging class="w3-center"
                                :click-handler="loadPage"
                                :prev-text="'Prev'"
                                :next-text="'Next'"
                                :container-class="'w3-bar'"
                                :page-count="pages.ttlhalaman"
                                :page-range="10"
                                :margin-pages="2"
                                :value="pages.halaman">
                            </Paging>
                        </template>
                    </vue-scrolling-table>
                </div>
				
			</div>
        </div>
        <div id="modalSiswa" class="w3-modal">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:600px;position: absolute;top: 50px;" id="modalBodySiswa">
                <header class="w3-container w3-theme-d3 w3-round-large" style="cursor:move;" id="modalBodySiswaheader" >
                    <span onclick="document.getElementById('modalSiswa').style.display='none'"
                    class="w3-button w3-display-topright w3-round-large">&times;</span>
                    <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                </header>
                <div class="w3-container w3-padding w3-theme-l4">
                    <form method="post" id="formSiswa" v-on:submit.prevent="saveData" action="#" autocomplete="off" >
                        <!-- <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="siswa_singkatan">{{findObjFind('name','siswa_singkatan')}} :</label>
                            </div>
                            <div class="w3-col l4 m9 s12">
                                <input ref="siswa_singkatan" required type="text" class="w3-input w3-small" id="siswa_singkatan" :placeholder="findObjFind('name','siswa_singkatan')" v-model="$store.getters['modSiswa/rec'].siswa_singkatan">
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="siswa_name">{{findObjFind('name','siswa_name')}} :</label>
                            </div>
                            <div class="w3-col l10 m9 s12">
                                <input ref="siswa_name" required type="text" class="w3-input w3-small" id="siswa_name" :placeholder="findObjFind('name','siswa_name')" v-model="$store.getters['modSiswa/rec'].siswa_name">
                            </div>
                        </div> -->
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="siswa_status">&nbsp;</label>
                            </div>
                            <div class="w3-col l2 m9 s12">
                                <label class="container w3-small">{{findObjFind('name','siswa_status')}}
                                    <input id="siswa_status" v-model="$store.getters['modSiswa/rec'].siswa_status" type="checkbox" true-value="T" false-value="F">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                    </form>
                </div>
                <footer class="w3-container w3-theme-l1">
                    <label class="container w3-small w3-left" style="margin-top: 15px;">Auto add
                        <input true-value="T" false-value="F" v-model="pages.autoadd" type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                    </label>
                    <h6 class="w3-right">
                        <button form="formSiswa" type="submit" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                        <button onclick="document.getElementById('modalSiswa').style.display='none'" class="w3-btn w3-small w3-red"><i class="fa fa-close"></i> Close</button>
                    </h6>
                </footer>
            </div>
        </div>
		
	</div>
</template>
<script>
import moment from "moment";
export default {
    head: {
        title: {
            inner: "Master Siswa"
        },
        script: []
    },
    name: "Siswa",
    components: {},
    data () {
        return {
            "header" : {"siswa_menu_status":true, "siswa_menu_add" : true, "siswa_menu_edit" : true, "siswa_menu_delete" : true, "siswa_menu_print": true},
            "recAkses": {"detail":[],"add":"F","edit":"F","delete":"F","print":"F"},
            "grid": false,
            "screenHeight": 0,
            "pages": {"halaman": 1, "batas": 1, "ttlhalaman": 0, "ttlrec": 0, "autoadd": "T"},
            "filters": {"fields":[]},
            "json_fields": { },
            "money": {"decimal": ",", "thousands": ".", "prefix": "", "suffix": "", "precision": 0, "masked": false}
        };
    },
    methods: {
        findObjFind (key, val) {
            return this.findObj(this.filters.fields, "key", val)[key];
        },
        findObjDetail (key, val) {
            // console.log(key, val);
            return this.findObj(this.filterDetails.fields, "key", val)[key];
        },
        setTextFind (txt, field) {
            const self = this;
            let fieldvalue = "";
            let ketemu = false;
            if (self.filters.find !== undefined) {
                if (self.filters.find.length > 0) {
                    self.filters.find.map(function (value) {
                        if (value.field === field) {
                            ketemu = true;
                        }
                        if (fieldvalue !== "null") {
                            fieldvalue += (fieldvalue !== "" ? ";" : "") + value.fieldValue;
                        } else {
                            fieldvalue += (fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                        }
                    });
                }
            }
            if (ketemu) {
                return self.sethighlight(txt, fieldvalue);
            }
            return txt;
        },
        loadPage (pageNum) {
            this.pages.halaman = pageNum;
            this.$store.dispatch("modSiswa/setPages",this.pages);
            this.$store.dispatch("modSiswa/setFilters",this.filters);
            this.loadData();
        },
        setFilter (filter, operator) {
            this.filters.find = filter;
            this.filters.operator = operator;
            this.$store.dispatch("modSiswa/setFilters",this.filters);
            this.loadData();
        },
        loadData () {
            this.$store.dispatch("modSiswa/setSelectedRow",-1);
            // this.$store.dispatch("modSiswaMenu/setSelectedRow",-1);
            this.$store.dispatch("modSiswa/loadData");
        },
        async showAdd () {
            let key = await this.getCode();
            this.$store.dispatch("modSiswa/setRec",{
                "event" : "add",
                "pilih": false,
                "siswa_code": key,
                "siswa_singkatan": "",
                "siswa_name": "",
                "sekolah_code": "c4ca4238a0b923820dcc509a6f75849b",
                "siswa_status": "T",
                "user_edit": localStorage.username,
                "user_entry": localStorage.username,
                "date_entry": moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss"),
                "date_edit": moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss")
            });
            document.getElementById("modalSiswa").style.display = "block";
            document.getElementById("siswa_singkatan").focus();
        },
        showEdit (dt, idx) {
            this.$store.dispatch("modSiswa/setSelectedRow",idx);
            this.$store.dispatch("modSiswa/setRec",dt);
            document.getElementById("modalSiswa").style.display = "block";
        },
        showDelete (dt) {
            const self = this;
            // eslint-disable-next-line no-undef
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        let vparams = { "siswa_code": dt["siswa_code"] };
                        self.apiPost("/public/siswa/delete", vparams, function (response) {
                            if (response.success === true) {
                                self.$toast.success("Hapus sukses", "Delete");
                                self.$store.dispatch("modSiswa/deleteRecs", self.$store.getters["modSiswa/selectedRow"] );
                            } else {
                                self.$toast.error("Gagal Hapus " + response.msg, "Delete");
                            }
                        });
                    }
                }
            );
        },
        showPrint () {},
        setStatus (dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            }
            return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
        },
        saveData (event) {
            const self = this;
            self.apiPost("/public/siswa/" + self.$store.getters["modSiswa/rec"].event, self.$store.getters["modSiswa/rec"], function (response) {
                
                if (response.success) {
                    if (self.$store.getters["modSiswa/rec"].event == "add") {
                        self.$store.getters["modSiswa/rec"].id = response.key;
                        self.$store.dispatch("modSiswa/addRecs", self.$store.getters["modSiswa/rec"]);
						
                        // self.rec.id = response.key;
                        // self.$store.dispatch("modSiswa/addRecs", self.rec);
                    } else {
                        self.$store.dispatch("modSiswa/editRecs", {"rec":self.$store.getters["modSiswa/rec"], "index": self.$store.getters["modSiswa/selectedRow"]});
                    }
                    document.getElementById("modalSiswa").style.display = "none";
                    self.$toast.success("Sukses Simpan data", "save");
                    if (self.pages.autoadd === "T") {
                        self.showAdd();
                    }
                } else {
                    // swal({title: "Save",text: "Gagal simpan data",type: "error",html: true});
                    self.$toast.error("Simpan gagal " + response.msg, "Save");
                }
            });
            event.preventDefault();
            return false;
        },
        setFilterTable(dt){
            if(!this.setFilterHeader(dt, this.filters)) {
                this.loadData();
            }
        }
    },
    computed: {
        getRecs() {
            return this.$store.getters["modSiswa/recs"];
        }
    },
    filters: {
        toRp(value) {
            // eslint-disable-next-line new-cap
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        }
    },
    created(){
        this.$store.getters["modSiswa/filters"].find = [];
        this.pages = this.$store.getters["modSiswa/pages"];
        this.filters = this.$store.state.modSiswa.filters;
        // this.filterDetails = this.$store.state.modSiswaMenu.filters;
        // if (this.$store.getters["modMenu/recs"].length==0){
        //     this.$store.dispatch("modMenu/loadData");
        // }
    },
    mounted () {
        this.recAkses = Object.assign({}, this.$store.state.modAksesmenu.recAkses[this.$route.name.toUpperCase()]);
        this.screenHeight = window.innerHeight -190;
        this.getGridColumnmAutoWidth("grid",this.filters.fields, false);
        this.loadData();
        const self = this;
        this.eventGridStore(this, "grid", "modSiswa/selectedRow",function(nilai){
            self.$store.dispatch("modSiswa/setSelectedRow",nilai);
        });
        this.dragElement(document.getElementById("modalBodySiswa"));
    }
};
</script>
